import * as ACTION_TYPES from './Types'

const initialState = {
    message: "",
    error: null,
    isLoggedIn: false,
    user: {},
    user_role: ""
}

const LoginReducer = (state = initialState, action) => {
    switch (action.type) {
        case ACTION_TYPES.LOGIN_SUCCESS:
            localStorage.setItem('authToken', JSON.stringify(action.payload.token));
            localStorage.setItem('user', JSON.stringify({ userName: action.payload.user_name, userId: action.payload.user_id, user_role: action.payload.user_role, image: action.payload.image }));
            return {
                ...state,
                user: {
                    userId: action.payload.user_id,
                    userName: action.payload.user_name,
                    user_role: action.payload.user_role,
                    image: action.payload.image
                },
                message: action?.payload?.data?.message || '',
                isLoggedIn: true,
            }
        case ACTION_TYPES.AUTHENTICATE_USER:
            return {
                ...state,
                isLoggedIn: true,
                // token: action.payload
                user: action.payload
            }

        case ACTION_TYPES.AUTHENTICATE_FAILED:
            localStorage.removeItem("authToken");
            localStorage.removeItem("user_name");
            return {
                ...state,
                isLoggedIn: false,
                token: ''
            }

        default:
            return state
    }
}

export default LoginReducer