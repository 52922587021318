import React, { lazy, Suspense } from "react";
import { BrowserRouter, Route, Switch } from "react-router-dom";

import 'react-redux-toastr/lib/css/react-redux-toastr.min.css';
import ReduxToastr from "react-redux-toastr";
import { authenticate } from "./Redux/Login/Actions";
import { AUTHENTICATE_FAILED } from "./Redux/Login/Types";
import store from './Redux/store'
import "./config/AxiosConfig";

//Check if userdata is available in session storage
const token = localStorage.getItem("authToken") || null
const user = localStorage.getItem("user") || null

if (token && user) {
	store.dispatch(authenticate(user));
} else {
	store.dispatch({ type: AUTHENTICATE_FAILED });
}

const loading = () => <div className="">Ganpati Bappa Morya -- Loading...</div>;

const DefaultLayout = lazy(() => import("./components/layout"));
const ScrollToTop = React.lazy(() => import('./components/common/scroll'));

const App = () => {
	return (
		<BrowserRouter>
			<ReduxToastr />
			<Suspense fallback={loading()}>
				<ScrollToTop>
					<Switch>
						<Route path="/" component={DefaultLayout} />
					</Switch>
				</ScrollToTop>
			</Suspense>
		</BrowserRouter>
	);
};

export default App;
