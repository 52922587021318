import React from "react";
import ReactDOM from "react-dom";
import AppRoute from "./App";
import reportWebVitals from "./reportWebVitals";
import "react-datepicker/dist/react-datepicker.css";

import './style.scss';
import { Provider } from "react-redux";
import store from "./Redux/store";

ReactDOM.render(
    // <React.StrictMode>
    <Provider store={store}>
        <AppRoute />
    </Provider>
    // </React.StrictMode>
    ,
    document.getElementById("root")
);
reportWebVitals();
