import { combineReducers } from 'redux'
import { reducer as formReducer } from 'redux-form' // redux-form reducer 
import { reducer as toastrReducer } from 'react-redux-toastr'
import loginReducer from './Login/Reducer'

export const rootReducer = combineReducers({
  toastr: toastrReducer,
  form: formReducer,
  login: loginReducer
});
