import axios from 'axios'
import * as ACTION_TYPES from './Types'
import { API_BASE } from '../../config/AppConstant'

const validatedRespose = (res) => res?.data || { return_data: [] };

//To reAuthenticate if the cookie is not expired
export const authenticate = user => dispatch => {
    if (!user.user_role) {
        return dispatch({ type: ACTION_TYPES.AUTHENTICATE_FAILED })
    }
    if (user) {
        return dispatch({ type: ACTION_TYPES.AUTHENTICATE_USER, payload: user })
    } else {
        return dispatch({ type: ACTION_TYPES.AUTHENTICATE_FAILED })
    }
}

//To Login
export const login = user => dispatch => {
    return axios
        .post(`${API_BASE}Login_Email_Id`, { ...user })
        .then((res) => {
            // set access token for rest of the api
            axios.defaults.headers.common['Authorization'] = res.data.token;

            dispatch({ type: ACTION_TYPES.LOGIN_SUCCESS, payload: res.data })
            return res.data
        })
        .catch((err) => {
            dispatch({ type: ACTION_TYPES.LOGIN_ERROR, payload: err.response })
            throw err
        })
}

export const forgotPassword = (data) => () => {
    return axios
      .post(`${API_BASE}patient/auth/forgotPassword`, data)
      .then((res) => {
        return validatedRespose(res);
      })
      .catch((err) => {
        throw err;
      });
  };